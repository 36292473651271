import { Path } from "enums";
import ProductDetailsModule from "modules/products/product-details/product-details.module";
import ProductsModule from "modules/products/products.module";
import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";

const ProductsPage = () => {
  return (
    <Switch>
      <Route exact path={Path.Products} component={ProductsModule} />
      <Route path={Path.AddProduct} component={ProductDetailsModule} />

      {/* <Redirect to={Path.Stations} /> */}
    </Switch>
  );
};

export default ProductsPage;
