import React, { Fragment, useCallback, useContext, useState } from "react";
import { useApi, useMount } from "hooks";
import { AppContext, FeatureFlagContext, UserContext } from "contexts";
import { Route, Switch } from "react-router-dom";
import { Path } from "enums";
import AppLoader from "components/commons/app-loader/app-loader";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Header from "components/commons/header/header";
import Sidebar from "components/commons/sidebar/sidebar";
import { getUserV2 } from "apis/profile.api";
import PromosPage from "./promos.page";
import StationsPage from "./stations.page";
import ProductsPage from "./products.page";

const AuthorizedPage = () => {
  const { appState } = useContext(AppContext);
  const { setUserEmail } = useContext(FeatureFlagContext);
  const [user, setUser] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { request: getUserRequest, loading: fetchingUser } = useApi({
    api: getUserV2,
    pageError: true,
  });

  useMount(() => {
    fetchUser();
  });

  const fetchUser = useCallback(async () => {
    const res = await getUserRequest();
    setUser(res);
    setUserEmail(res.email);
  }, [getUserRequest, setUserEmail]);

  const loading = fetchingUser || appState?.loading;
  return (
    <UserContext.Provider value={{ user, fetchUser, loading: fetchingUser }}>
      {loading ? (
        <AppLoader />
      ) : user ? (
        <Fragment>
          <Header onClick={() => setIsDrawerOpen(!isDrawerOpen)} />
          <Sidebar />

          <div
            style={{
              marginLeft: "196px",
              marginTop: "60px",
              padding: "27px 36px",
              minWidth: "1170px",
              overflowX: "auto",
              minHeight: "100vh",
              backgroundColor: "#fff",
            }}
          >
            <div
              style={{
                overflowX: "auto",
                padding: "27px 36px",
              }}
            >
              <Switch>
                <Route path={Path.PromosList} component={PromosPage} />
                <Route path={Path.Stations} component={StationsPage} />
                <Route path={Path.Products} component={ProductsPage} />
                <Redirect to={Path.Stations} />
              </Switch>
            </div>
          </div>
        </Fragment>
      ) : null}
    </UserContext.Provider>
  );
};

export default AuthorizedPage;
