import { Intro } from "components/commons";
import { Path } from "enums";
import { useRouter } from "hooks";
import locale from "localization";
const ProductsModule = () => {
  const router = useRouter();
  return (
    <>
      <Intro
        title={locale.products}
        actionText={locale.addProduct}
        actionOnClick={() => router.push(Path.AddProduct)}
      />
    </>
  );
};

export default ProductsModule;
